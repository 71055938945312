import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../../services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrl: './create-email.component.css'
})
export class CreateEmailComponent {
  emailForm: FormGroup;
  selectedSubject: string = "N/A";
  showError: boolean = false;
  constructor(private router: Router,public dialogRef: MatDialogRef<CreateEmailComponent>, 
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private emailService: EmailService) {
    this.emailForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      companyName: [''],
      referal: ['Linkedin'],
      message: ['']
    });
  }


  onSubmit() {
    this.showError = this.selectedSubject === 'N/A';
    if(!this.showError){
      this.emailForm.addControl('Subject', this.fb.control(this.selectedSubject));
    
      this.emailService.sendEmail(this.emailForm.value).subscribe({
        next: (response) => {
          this.dialogRef.close(this.data);
          this.router.navigate([{ outlets: { modal: 'emailthankyou' } }])
        },
         
        error: (error) => {
          this.snackBar.open(error.message, 'Failed', {
            duration: 3000,
            panelClass: ['snackbar-error'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        }
      });
    }
  }
  data(data: any) {
    throw new Error('Method not implemented.');
  }

  onSelectionChange(){
    this.showError = false;
  }

  onCloseClick(data:any) {
    this.dialogRef.close(data);
  }
}
