<div class="container-fluid px-lg-5 px-3 py-5 emailContainer">
  <div class="d-flex justify-content-between flex-column flex-md-row">
    <h1 class="mb-3 mb-md-0">Create Email</h1>
    <div class="col-xl-4 col-xxl-4 my-3 mt-md-0">
      <select aria-label="Select Subject" class="form-select" [(ngModel)]="selectedSubject"
        [class.is-invalid]="showError" (change)="onSelectionChange()">
        <option value="N/A">Select Subject</option>
        <option value="General">General</option>
        <option value="Sales">Sales</option>
        <option value="Support">Support</option>
        <option value="Technical">Technical</option>
      </select>
    </div>
  </div>
  <form [formGroup]="emailForm" (ngSubmit)="onSubmit()" class="row g-3">
    <div class="col-md-6">
      <label for="firstName" class="form-label">First Name</label>
      <input type="text" class="form-control" id="firstName" formControlName="firstName" required
        placeholder="Provide First Name">
    </div>
    <div class="col-md-6">
      <label for="lastName" class="form-label">Last Name</label>
      <input type="text" class="form-control" id="lastName" formControlName="lastName" required
        placeholder="Provide Last Name">
    </div>
    <div class="col-md-6">
      <label for="email" class="form-label">Email Address</label>
      <input type="email" class="form-control" id="email" formControlName="email" placeholder="Enter your email address"
        required>
    </div>
    <div class="col-md-6">
      <label for="phone" class="form-label">Phone Number</label>
      <input type="tel" class="form-control" id="phone" formControlName="phone" placeholder="Provide phone number"
        required>
    </div>
    <div class="col-md-6">
      <label for="companyName" class="form-label">Company Name</label>
      <input type="text" class="form-control" id="companyName" formControlName="companyName"
        placeholder="Provide Company Name" required>
    </div>
    <div class="col-md-6">
      <label for="referal" class="form-label">Where did you hear about us?</label>
      <select aria-label="Select Referal" class="form-select" formControlName="referal">
        <option value="Linkedin">Linkedin</option>
        <option value="Google">Google</option>
        <option value="Twitter" selected>Twitter</option>
        <option value="Referal">Referal</option>
      </select>
    </div>
    <div class="col-12">
      <label for="message" class="form-label">Your Message</label>
      <textarea class="form-control" id="message" formControlName="message" placeholder="Type your message here"
        required></textarea>
    </div>
    <div class="col-12 d-flex flex-lg-row flex-column justify-content-center mt-5">
      <div class="col-lg-6 col-12">
        <button type="submit" class="btn btn-secondary w-100 me-lg-2 me-0">Send Email</button>
        <!-- [disabled]="!emailForm.valid" -->
      </div>
      <div class="col-lg-6 col-12 mt-2 mt-lg-0">
        <button type="button" class="btn btn-outline-primary w-100 ms-lg-2 ms-0"
          (click)="onCloseClick({status: false, messege: ''})">Cancel</button>
      </div>
    </div>
  </form>
</div>